<!--Start of presentation-->
<cewe-results-start *ngIf="this.tagId == '' || this.tagId == undefined" (tags)="getTagsFromStart($event)"></cewe-results-start>
<!--Presentation-->
<div class="presentation_container" *ngIf="this.tagId != '' && this.tagId != undefined && !endSequence">
    <div class="tagName">
        <div>
            <h2>{{tagName}}</h2>
            <h3>#{{tag}}</h3>
        </div>
    </div>
    <div *ngIf="this.results != undefined && this.results.products != undefined" class="productList">
        <cewe-result-product *ngFor="let product of this.results.products; index as i;" [product]="product" [placement]="i + 1" style="background-color: transparent;"></cewe-result-product>
    </div>
    <div *ngIf="this.results == undefined || this.results.products == undefined || this.results.products.length == 0" class="productList">
        <h1>Keine Produkte für diesen Tag.</h1>
        <h2>No products for this tag.</h2>
    </div>
    <button *ngIf="isBeforeAvailable()" class="beforeButton" (click)="before()"><mat-icon>keyboard_arrow_left</mat-icon></button>
    <button *ngIf="isNextAvailable()" class="nextButton" (click)="next()"><mat-icon>keyboard_arrow_right</mat-icon></button>
    
    <!-- CAN BE DELETED IF THEY DON'T WANT IT EVER 
        <div class="wave">
        <div class="waveOverlay"></div>
        <img src="assets/wave.svg">
    </div> -->
    <img class="logo" src="assets/logo.svg">
</div>
<!--End of presentation-->
<cewe-result-end *ngIf="endSequence"></cewe-result-end>