import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserRestService} from './user-rest.service';
import {environment} from '../../environments/environment';
import {UserRequest} from '../model/request/UserRequest';
import {User} from '../model/User';
import {TranslateDataService} from './translate.service';
import {ConfigService} from './config-rest.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate {
    loggedIn = false;
    behaviourSubject: BehaviorSubject<boolean>;
    observable: Observable<boolean>;

    constructor(public router: Router, private userService: UserRestService, private translateService: TranslateDataService, private configService: ConfigService) {
        this.behaviourSubject = new BehaviorSubject<boolean>(undefined);
        this.observable = this.behaviourSubject.asObservable();
    }

    checkForValidUserId() {
        if (this.checkValidTenant() && this.userService.isValidUserId()) {
            this.userService.getUser().subscribe((user: User) => {
                this.translateService.setLanguage(user.language, false);
                this.loggedIn = true;
                this.behaviourSubject.next(true);
            }), (err: any) => {
                localStorage.clear();
                this.loggedIn = false;
                this.translateService.setLanguageToNavigatorLanguage();
            };
        } else {
            this.translateService.setLanguageToNavigatorLanguage();
        }
    }

    canActivate(): boolean {
        this.checkValidTenant();
        if (!this.userService.isValidUserId()) {
            if (this.configService.authType !== 'NONE') {
                this.router.navigate(['login']).then(r => ['dashboard']);
            } else {
                this.userService.postUser(new UserRequest(this.translateService.language, environment.tenantId, '')).subscribe((user: User) => {
                    localStorage.setItem(environment.localStorageName, user.userId);
                    this.loggedIn = true;
                    this.behaviourSubject.next(true);
                });
                return true;
            }
            return false;
        } else {
            this.loggedIn = true;
            this.behaviourSubject.next(true);
            return true;
        }
    }

    checkValidTenant(): boolean {
        if (environment.tenantId.toString() === localStorage.getItem('tenantId')) {
            return true;
        } else {
            localStorage.clear();
            localStorage.setItem('tenantId', environment.tenantId.toString());
            return false;
        }
    }
}
