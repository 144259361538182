<div class="language-background" *ngIf="languages.length > 1" (click)="toggleCircle()">
    <div [ngClass]="circleClass"></div>
    <div [ngClass]="selectedLanguageClass">
        <img class="flag-image" src="assets/languages/{{this.translateService.language}}.svg"
             alt="{{this.translateService.language.toString().toUpperCase()}}">
    </div>
    <div [ngClass]="dropdownClass">
        <div *ngFor="let language of this.languages" (click)="setLanguage(language)">
            <tr class="language mt-1">
                <td class="flag">
                    <img src="assets/languages/{{language}}.svg" alt="{{language.toString().toUpperCase()}}">
                </td>
            </tr>
        </div>
    </div>
</div>
