<div class="container col-lg-4">
    <img class="logo" src="assets/innodays-2023.png" alt="Innodays Logo">
    <div *ngIf="tenantId === 2" class="text">
        {{'shipitdays.final' | translate}}
    </div>
    <div *ngIf="tenantId === 1" class="text">
        {{'innodays.final' | translate}}
    </div>
</div>

<div class="home-button" routerLink="/dashboard">
    <img src="assets/home.svg" alt="Home">
</div>