import { Injectable } from "@angular/core";
import { AbstractRestService } from "./abstract-rest.service";
import { Observable, map } from "rxjs";
import { ResultResponse } from "../model/ResultResponse";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class ResultsRestService extends AbstractRestService{
    protected get resourcePathSegment(): string {
        return "results"
    }

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getResultsOfTag(tagId: string, amount?: number): Observable<ResultResponse> {
        if (amount != undefined) {
            const option = new HttpParams().append("amount", amount);
            return this.get(tagId, option).pipe(map(result => result as ResultResponse));
        } else {
            return this.get(tagId).pipe(map(result => result as ResultResponse));
        }
    }

}