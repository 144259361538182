export class CommentRequest {
    public message: string;

    constructor(message: string) {
        this.message = message;
    }

    static fromJson(json: any): CommentRequest {
        return new CommentRequest(json.message);
    }
}
