import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Variant } from "src/app/model/Variant";
import { ConfigService } from "src/app/services/config-rest.service";
import { ProductRestService } from "src/app/services/product-rest.service";
import { TagRestService } from "src/app/services/tag-rest.service";
import { UserRestService } from "src/app/services/user-rest.service";
import { VoteService } from "src/app/services/vote.service";
import { environment } from "src/environments/environment";
import { FeedbackComponent } from "../../feedback/feedback.component";
import { filter } from "lodash";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserVote } from "src/app/model/UserVote";
import { VaraintId } from "src/app/model/VariantId";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, map } from "rxjs";

@Component({
    selector: 'cewe-product-variant',
    templateUrl: './product-variant.component.html',
    styleUrls: ['./product-variant.component.scss']
})
export class ProductVariantComponent implements OnInit {
    @Input() variants: Variant[];
    @Input() multipleSelectable: boolean;
    @Input() userVotes: BehaviorSubject<UserVote[]>;
    @Input() productId: String;
    @Input() productIsLiked: boolean;

    formGroup = this._formBuilder.group({});

    constructor(private readonly productService: ProductRestService,
                private translationService: TranslateService,
                private voteService: VoteService,
                private configService: ConfigService,
                private _formBuilder: FormBuilder
    ) {}

    ngOnInit() {
        this.generateFormHashmap().subscribe(hashmap => {
            this.formGroup = this._formBuilder.group(hashmap);
        })
    }

    getVariantTitle(variantId: string): string {
        let variant = this.variants.filter(v => v.id == variantId);
        if (variant.length == 1) {
            if (variant[0].translations.length != 0) {
                let filteredTitle = variant[0].translations.filter(translation =>  translation.language == this.translationService.currentLang);
                if (filteredTitle.length > 0) {
                    return filteredTitle[0].title;
                } else {
                    return variant[0].translations[0].title;
                }
            }
        } else {
            return "UNKNOWN ID: " + variantId
        }
    }

    alertFormValues(formGroup: FormGroup) {
        alert(JSON.stringify(formGroup.value, null, 2));
    }

    private generateFormHashmap(): Observable<{}> {
        return this.userVotes.pipe(map(votes => {
            var result = new Map<String, String>();
            this.variants.forEach(variant => {
                var isVotedFor = false;
                votes.forEach(vote => {
                    if (vote.productId == this.productId) {
                        vote.variants.forEach(variantId => {
                            if(variantId.value == variant.id) {
                                isVotedFor = true;
                            }
                        })
                    }
                })
                result[variant.id] = isVotedFor;
            })
            return result
        }));
    }

    slideButtonClicked(variantId: string) {
        if (!this.formGroup.value[variantId]) {
            this.formGroup[variantId] = false;
            this.voteService.voteForVariant(new Array<VaraintId>(), this.productId, this.productIsLiked);
        } else {
            let newMap = {};
            this.variants.forEach(variant => {
                newMap[variant.id] = variant.id == variantId
            })
            this.formGroup = this._formBuilder.group(newMap);
            this.voteService.voteForVariant(new Array<VaraintId>(new VaraintId(variantId)), this.productId, this.productIsLiked);
        }
    }

    checkmarkClicked() {
        var variants = new Array<VaraintId>();
        this.variants.forEach(variant => {
            if (this.formGroup.value[variant.id] == true) {
                variants.push(new VaraintId(variant.id));
            }
        })

        this.voteService.voteForVariant(variants, this.productId, this.productIsLiked);
    }
}
