import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractRestService} from './abstract-rest.service';
import {Agenda} from '../model/Agenda';

@Injectable({
    providedIn: 'root'
})
export class EventRestService extends AbstractRestService {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected get resourcePathSegment(): string {
        return 'dashboard';
    }

    public getAllEvents(language: string): Observable<Agenda[]> {
        return this.get('events?language=' + language).pipe(map((eventsJson: any) => {
            const events: Agenda[] = [];
            eventsJson.events.forEach(eventsJson => events.push(Agenda.fromJson(eventsJson)));
            return events;
        }));
    }
}
