import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {RatingOption} from 'src/app/model/RatingOption';
import {ConfigService} from 'src/app/services/config-rest.service';
import {environment} from 'src/environments/environment';
import {Image} from '../../../model/Image';
import {Product} from '../../../model/Product';
import {ProductRestService} from '../../../services/product-rest.service';
import {TagRestService} from '../../../services/tag-rest.service';
import {UserRestService} from '../../../services/user-rest.service';
import {VoteService} from '../../../services/vote.service';
import {FeedbackComponent} from '../feedback/feedback.component';
import { UserVote } from 'src/app/model/UserVote';
import { VotingStatusDialogComponent } from '../voting-status-dialog/voting-status-dialog.component';

const FUTURE_ZONE = "Future Zone";


export interface FeedbackDialogData {
    feedbackProduct: Product;
}

@Component({
    selector: 'cewe-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
    @Input() product: Product;
    @Input() isLiked: boolean;
    @Input() userVotes: UserVote[];
    
    imageUrls: {}[] = [];
    ratingOptions: RatingOption[];
    votingEnabled: boolean;

    constructor(private readonly productService: ProductRestService,
                private dialog: MatDialog,
                private voteService: VoteService,
                private tagService: TagRestService,
                private userService: UserRestService,
                private configService: ConfigService,
    ) {
        this.configService.observable.subscribe(() => {
            this.ratingOptions = this.configService.ratingOptions;
            this.votingEnabled = this.configService.votingEnabled;
        });
    }

    ngOnInit() {
        if (this.product.images != undefined) {
            this.product.images.forEach(imageUrl => {
                if (imageUrl.imageId != undefined) {
                    this.imageUrls.push(
                        {
                            path: environment.baseUrl + '/images/' + imageUrl.imageId + '.jpg?uid=' + this.userService.getUserId() + '&size=W_700'
                        }
                    )
                }
            });
        }
    }

    openFeedbackModal() {
        this.dialog.open(FeedbackComponent, {
            width: '50%',
            panelClass: ['col-lg-4', 'col-12', 'feedback-background'],
            data: {feedbackProduct: this.product},
        });
    }

    toggleTag(tag: string) {
        this.tagService.searchTag(tag);
    }

    likeProduct() {
        if (this.isLiked) {
            this.voteService.unvoteForProduct(this.product);
            this.isLiked = false;
        } else if (this.voteService.votesLeft.value != 0){
            this.voteService.voteForProduct(this.product).subscribe({
                next: () => {
                    this.voteService.deleteVote()
                    this.isLiked = true;
                },
                error: value => {
                    if (value.status == 423 || value.status == 451) {
                        this.dialog.open(VotingStatusDialogComponent, {
                            data: {statusCode: value.status}
                        });
                    }
                }
            });
        }
    }

    isFutureZone(): boolean {
        var isFZ = false;
        if (this.product.tags != undefined && this.product.tags.length > 0) {
            this.product.tags.forEach(tag => {
                if (tag.tagName == FUTURE_ZONE) {
                    isFZ = true;
                }
            })
        }
        return isFZ
    }
}
