import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateDataService} from '../../services/translate.service';
import {TranslateService} from '@ngx-translate/core';
import {AbstractTranslationService} from '../../services/abstract-translation.service';

@Component({
    selector: 'cewe-votesmessage',
    templateUrl: './votesmessage.component.html',
    styleUrls: ['./votesmessage.component.scss']
})
export class VotesmessageComponent extends AbstractTranslationService implements OnInit {

    constructor(public dialogRef: MatDialogRef<VotesmessageComponent>,
                public translateService: TranslateDataService,
                public translate: TranslateService) {
        super(translate, translateService);
    }

    ngOnInit() {
        (document.querySelector('html') as HTMLElement).style.overflowY = 'unset';
    }

    closeVoteMessageModal() {
        this.dialogRef.close();
    }
}
