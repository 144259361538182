import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { RatingOption } from '../model/RatingOption';
import { AbstractRestService } from './abstract-rest.service';
import { VoteService } from './vote.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigService extends AbstractRestService {
    behaviourSubject: BehaviorSubject<string>;
    observable: Observable<string>;
    client: string;
    authType: string;
    ratingOptions: RatingOption[];
    supportedLanguages: string[];
    votingEnabled: boolean;

    constructor(httpClient: HttpClient, private voteService: VoteService) {
        super(httpClient);
        this.behaviourSubject = new BehaviorSubject<string>(undefined);
        this.observable = this.behaviourSubject.asObservable();
        this.supportedLanguages = [];
    }

    protected get resourcePathSegment(): string {
        return 'configs';
    }

    public getTenantConfig(): Observable<Map<string, string>> {
        return this.get(environment.tenantId.toString()).pipe(map((tenantConfigJson: any) => {
            const configMap = new Map<string, string>();
            if (tenantConfigJson !== null) {
                for (const value in tenantConfigJson) {
                    if (value) {
                        configMap.set(value, tenantConfigJson[value]);
                    }
                }
            }
            this.initializeEnvironmentVariables(configMap);
            return configMap;
        }));
    }

    private initializeEnvironmentVariables(configMap: any) {
        this.client = configMap.get('name');
        this.authType = configMap.get('authType');
        this.voteService.maxVotes = configMap.get('numberOfVotes');
        this.supportedLanguages = configMap.get('supportedLanguages');
        this.ratingOptions = configMap.get('ratingOptions');
        this.votingEnabled = configMap.get('votingEnabled');
        this.behaviourSubject.next(this.client);
    }
}
