<!-- tile -->
<div class="tile">
    <!-- img / slideshow -->
    <ng-container *appLazyLoad>
        <div class="tile-img" *ngIf="product.images && product.images.length != 0">
            <carousel class="imageShow" [images]="imageUrls" [cellWidth]="'100%'" [autoplay]="false"
                [arrows]="this.imageUrls && this.imageUrls.length > 1" [pauseOnHover]="true" [loop]="true"
                [transitionTimingFunction]="'ease-in'" [transitionDuration]="300" [lightDOM]="true" [objectFit]="'scale-down'">
            </carousel>
        </div>
    </ng-container>

    <!-- tile header -->
    <div  [ngClass]="product.images != undefined ? 'tile-header' : 'tile-header-alt'">
        <!-- title and feedback button with img -->
        <h2 [ngClass]="product.images != undefined && product.images.length !== 0 ? 'tile-title' : 'tile-title-noImage'">{{product.name}}</h2>
    </div>
        
        <!-- tile body -->
    <div class="tile-body">
        <ngx-md class="tile-info" *ngIf="product.description !== ''" [data]="product.description"></ngx-md><br>
        <cewe-product-variant 
            *ngIf="product.variants != undefined" 
            [variants]="product.variants" 
            [multipleSelectable]="product.multipleVariantsSelectable" 
            [userVotes]="userVotes"
            [productId]="this.product.productId"
            [productIsLiked]="this.isLiked"></cewe-product-variant>
        <p class="tile-info">{{product.responsible}}</p>
        
        <!-- tile tags -->
        <div class="tile-tags-container" *ngIf="product.tags != undefined && product.tags.length > 0">
            <div class="tile-tag" *ngFor="let productTag of product.tags" (click)="toggleTag(productTag.tagName)">
                {{productTag.tagName}}
            </div>
        </div>
    </div>
        
    <div class="feedbackRow">
        <button class="feedbackbutton" (click)="openFeedbackModal()">
            <img class="feedback-icon" src="assets/feedback.svg" alt="Feedback Icon">
        </button>
        <!-- likebutton -->
        <div *ngIf="!this.isFutureZone()">
            <button *ngIf="!this.isLiked" class="likebutton" (click)="likeProduct()">
                Like
            </button>
            <button *ngIf="this.isLiked" class="likebutton liked" (click)="likeProduct()">
                Liked
            </button>
        </div>
    </div>
    <div class="tile-action">
        <p class="tile-number">#{{product.customId}}</p>
    </div>
</div>