import {BrowserModule} from '@angular/platform-browser';
import {HostListener, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './framework/header/header.component';
import {OverviewComponent} from './routing/overview/overview.component';
import {ResultsComponent} from './routing/results/results.component';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from '@angular/common/http';
import {SortGridPipe} from './routing/overview/sort-grid-pipe';
import {NgxMdModule} from 'ngx-md';
import {ApiAccessKeyInterceptor} from './global/api-access-key-interceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LazyLoadDirective} from './routing/overview/lazy-load.directive';
import {ProductComponent} from './routing/overview/product/product.component';
import {FeedbackComponent} from './routing/overview/feedback/feedback.component';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion'; 
import {DashboardComponent} from './routing/dashboard/dashboard.component';
import {VotesmessageComponent} from './framework/votesmessage/votesmessage.component';
import {LoginComponent} from './routing/login/login.component';
import {AuthGuardService} from './services/auth-guard.service';
import {TranslateService, TranslateModule, TranslateLoader} from '@ngx-translate/core';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {ShipitDashboardComponent} from './routing/dashboard/shipit-dashboard/shipit-dashboard.component';
import {InnodaysDashboardComponent} from './routing/dashboard/innodays-dashboard/innodays-dashboard.component';
import {InnodaysDashboardSimpleComponent} from './routing/dashboard/innodays-dashboard-simple/innodays-dashboard-simple.component';

import {TranslationToggleComponent} from './framework/translation-toggle/translation-toggle.component';
import {ErrorProblemComponent} from './framework/error-problem/error-problem.component';
import {IvyCarouselModule} from 'angular-responsive-carousel2';
import { FinalComponent } from './routing/final/final.component';
import { AgendaComponent } from './routing/agenda/agenda.component';
import { ProductVariantComponent } from './routing/overview/product/product-variants/product-variant.component';
import { AgendaEntryComponent } from './routing/dashboard/innodays-dashboard/agenda-entry/agenda-entry.component';
import { AgendaDescriptorComponent } from './routing/agenda/agenda-descriptor/agenda-descriptor.component';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VotingStatusDialogComponent } from './routing/overview/voting-status-dialog/voting-status-dialog.component';
import { ResultsStartComponent } from './routing/results/results-start/results-start.component';
import { ResultsRestService } from './services/results-rest-service';
import { ResultProductComponent } from './routing/results/result-product/result-product.component';
import { ResultEndComponent } from './routing/results/result-end/result-end.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        SortGridPipe,
        HeaderComponent,
        OverviewComponent,
        ResultsComponent,
        LazyLoadDirective,
        ProductComponent,
        FeedbackComponent,
        DashboardComponent,
        VotesmessageComponent,
        LoginComponent,
        ShipitDashboardComponent,
        InnodaysDashboardComponent,
        TranslationToggleComponent,
        InnodaysDashboardSimpleComponent,
        ErrorProblemComponent,
        FinalComponent,
        AgendaComponent,
        ProductVariantComponent,
        AgendaEntryComponent,
        AgendaDescriptorComponent,
        VotingStatusDialogComponent,
        ResultsStartComponent,
        ResultProductComponent,
        ResultEndComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        NgxMdModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        NoopAnimationsModule,
        MatDialogModule,
        MatSnackBarModule,
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        IvyCarouselModule
    ],
    providers: [
        SortGridPipe,
        { provide: HTTP_INTERCEPTORS, useClass: ApiAccessKeyInterceptor, multi: true },
        AuthGuardService,
        TranslateService,
        ResultsRestService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    // testChanges1
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
