import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AbstractRestService} from './abstract-rest.service';
import {Comment} from '../model/Comment';
import {Product} from '../model/Product';
import {CommentRequest} from '../model/request/CommentRequest';
import {VoteRatingRequest} from '../model/request/VoteRatingRequest';
import {Router} from '@angular/router';
import { forEach } from 'lodash';
import { QueryNames } from '../model/request/parameters/Queries';
import { ProductOptions } from '../model/request/parameters/ProductOption';
import { ProductRatingRequest } from '../model/request/ProductVariantRequest';

@Injectable({
    providedIn: 'root'
})
export class ProductRestService extends AbstractRestService {
    private productsCache?: Product[] = null


    constructor(httpClient: HttpClient,
                private router: Router) {
        super(httpClient);
    }

    protected get resourcePathSegment(): string {
        return 'products';
    }

    public clearCache() {
        this.productsCache = undefined;
    }

    public getAllProducts(productOptions: ProductOptions[]): Observable<Product[]> {
        if (this.productsCache != undefined) {
            return of(this.productsCache);
        } else {
            let httpParam = new HttpParams();
            let options = "";
             productOptions.forEach((element, index) => {
                options += element;
                if (index < productOptions.length -1) {
                    options += ",";
                }
            });
            httpParam = httpParam.append(QueryNames.PRODUCTOPTIONS, options);
            return this.get("", httpParam).pipe(map((productsJson: Product[]) => {
                const products: Product[] = [];
                productsJson.forEach(productJson => {
                    products.push(Product.fromJson(productJson));
                });
                this.productsCache = products
                return products;
            }));
        }
    }

    public getProduct(productId: string): Observable<Product> {
        const path = `${productId}`;
        return this.get(path).pipe(map(productJson => {
            return Product.fromJson(productJson);
        }));
    }

    public createOrUpdateProductComment(productId: string, feedback: string): Observable<Comment> {
        const path = `${productId}/comments`;
        return this.put(path, new CommentRequest(feedback));
    }

    public deleteProductComment(productId: string): Observable<any> {
        const path = `${productId}/comments`;
        return this.delete(path);
    }
}
