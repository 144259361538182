<cewe-header></cewe-header>

<div class="translation-icon">
    <cewe-translation-toggle></cewe-translation-toggle>
</div>

<mat-accordion *ngIf="eventDays != undefined && eventDays[0] != undefined">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{'agenda.for' | translate}} {{dateOfFirstDay}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <cewe-agenda-descriptor *ngFor="let event of eventDays[0].events" [event]="event"></cewe-agenda-descriptor>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="eventDays != undefined && eventDays[1] != undefined">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{'agenda.for' | translate}} {{dateOfSecondDay}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <cewe-agenda-descriptor *ngFor="let event of eventDays[1].events" [event]="event"></cewe-agenda-descriptor>
    </mat-expansion-panel>
</mat-accordion>


<mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{'agenda.faq' | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>

            <img class="agenda"
                src="assets/flyer/CEWE_01_2024_InnovationDays_2024_Plakat_Bewertungsapp_A1_{{this.translateService.language}}.png"
                alt="Innodays 2024 Logo">
        </ng-template>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{'agenda.cmp' | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        {{'agenda.cmpDescription' | translate}} <br />

        <button mat-raised-button class="login cmp-button"
            onClick="window.open('https://www.cewe-myphotos.com/open/sharedEvent/72899eb9510b45208bdac2ec90ab901d')">
            {{'agenda.cmpButton1.1' | translate}} <br> {{'agenda.cmpButton1.2' | translate}}
        </button>

        <button mat-raised-button class="login cmp-button"
            onClick="window.open('https://www.cewe-myphotos.com/open/sharedEvent/ca9b435bc87143858e61722309f72448')">
            {{'agenda.cmpButton2.1' | translate}} <br> {{'agenda.cmpButton2.2' | translate}}
        </button>
    </mat-expansion-panel>
</mat-accordion>



<button class="home-background" (click)="openDashboard()">
    <img src="assets/home-overview.svg" alt="Home">
</button>