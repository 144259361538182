import { Tag } from "./Tag";
import { Variant } from "./Variant";
import { VariantStatistics } from "./VariantStatistics";
import { Image } from "./Image";

export class Product {

    productId: string;
    customId: number;
    language: string;
    name: string;
    description: string;
    responsible: string;
    tags?: Tag[];
    variants?: Variant[];
    numberOfVotes?: number;
    numberOfComments?: number;
    avgRating?: number;
    variantVotes?: VariantStatistics[];
    multipleVariantsSelectable?: boolean;
    ratingByOption?: [];
    images?: Image[];

    constructor(
        productId: string,
        customId: number,
        language: string,
        name: string,
        description: string,
        responsible: string,
        tags?: Tag[],
        variants?: Variant[],
        numberOfVotes?: number,
        numberOfComments?: number,
        avgRating?: number,
        variantVotes?: VariantStatistics[],
        multipleVariantsSelectable?: boolean,
        ratingByOption?: [],
        images?: Image[],
    ) {
        this.productId = productId;
        this.customId = customId;
        this.language = language;
        this.name = name;
        this.description = description;
        this.responsible = responsible;
        this.variants = variants;
        this.numberOfVotes = numberOfVotes;
        this.numberOfComments = numberOfComments;
        this.avgRating = avgRating;
        this.variantVotes = variantVotes;
        this.multipleVariantsSelectable = multipleVariantsSelectable;
        this.ratingByOption = ratingByOption;
        this.images = images;
        this.tags = tags;
    }

    static fromJson(json: any): Product {
        return new Product(
            json.productId,
            json.customId, 
            json.language, 
            json.name, 
            json.description, 
            json.responsible, 
            json.tags,
            json.variants, 
            json.numberOfVotes, 
            json.numberOfComments,
            json.avgRating, 
            json.variantVotes, 
            json.multipleVariantsSelectable, 
            json.ratingByOption, 
            json.images, 
        );
    }
}


export class TranslatedProductSupplement {
    constructor(
        public language: string,
        public name: string,
        public responsible: string,
        public description: string,
    ) {}
}