import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ProductRestService} from '../../services/product-rest.service';
import {Product} from '../../model/Product';
import {UserRestService} from '../../services/user-rest.service';
import {User} from '../../model/User';
import {catchError} from 'rxjs/operators';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {VERSION} from '../../../environments/version';
import {VoteService} from '../../services/vote.service';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {TranslateDataService} from 'src/app/services/translate.service';
import {TranslateService} from '@ngx-translate/core';
import {TagRestService} from '../../services/tag-rest.service';
import {AbstractTranslationService} from '../../services/abstract-translation.service';
import { ProductOptions } from 'src/app/model/request/parameters/ProductOption';
import { UserVote } from 'src/app/model/UserVote';

@Component({
    selector: 'cewe-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent extends AbstractTranslationService implements OnInit {

    version = `${VERSION.hash}-${VERSION.buildTime}`;

    content: Product[] = [];
    filteredContent: Product[] = [];

    votedProductsList: BehaviorSubject<UserVote[]> = new BehaviorSubject<UserVote[]>([]);
    userFeedbackMap = new Map<string, string>();

    search = '';
    showSearch = false;
    showLike = false;
    searchClass = 'search-background-closed';
    searchSpacerClass = 'search-spacer-closed';

    votesLeft: number;

    loading = true;
    tenantId: number;
    starsButton: boolean;

    constructor(private readonly productService: ProductRestService,
                public readonly userService: UserRestService,
                public voteService: VoteService,
                private tagService: TagRestService,
                private router: Router,
                public translate: TranslateService,
                public translateService: TranslateDataService
    ) {
        super(translate, translateService);
        this.starsButton = environment.starsButton;
        this.tenantId = environment.tenantId;

        this.voteService.votesLeft.subscribe(amount => {
            this.votesLeft = amount;
        });

        this.userService.getUserCommentedProducts().subscribe(userFeedbackMap => {
            this.userFeedbackMap = userFeedbackMap;
        });

        this.translateService.changeObservable.subscribe(languageChanged => {
            if (languageChanged == true) {
                this.productService.clearCache();
                this.updateContent();
                this.translateService.resetLanguageChangeObservable();
            }
        });
    }

    ngOnInit() {
        this.userService.getUserVotedProducts().subscribe(userVotes => {
            if (userVotes != undefined) {
                this.votedProductsList.next(userVotes);
            }
        });
        if (!this.userService.isValidUserId()) {
            this.router.navigate(['login']).then(r => ['']);
        } else {
            this.userService.getUser().pipe(
                catchError(errorResponse => {
                    if (errorResponse.status === 401) {
                        localStorage.removeItem(environment.localStorageName);
                        console.warn('Removing old uid and retrying 401 error.');
                    }
                    // we don't handle this error
                    return throwError(errorResponse);
                })
            ).subscribe( {
                next: (user: User) => {
                    this.votesLeft = user.votesLeft;
                    this.voteService.setVotes(user.votesLeft);
                    this.updateContent();
                }, 
                error: () => {
                    localStorage.clear();
                    environment.falseUserId = true;
                    this.router.navigate(['login']).then(r => ['']);
                }
            });
        }

        this.tagService.observable.subscribe((tag) => {
            if (tag) {
                this.searchForTag(tag);
                this.closeSearch();
            }
        });
    }

    /*/ only shows the tagService that has been clicked /*/
    private searchForTag(tag: string) {
        if (this.search !== tag) {
            this.showSearch = false;

            window.scroll(0, 0);
            this.search = tag;
            this.filteredContent = this.filterForTagName(this.content, tag);
            this.toggleSearch();
        } else {
            this.search = '';
            this.filteredContent = this.content;
            this.toggleSearch();
        }

    }

    /*/ only shows liked products /*/
    toggleGlobalLikeButton() {
        window.scrollTo(0, 0);
        if (this.showSearch) {
            this.toggleSearch();
        }

        if (!this.showLike) {
            this.userService.getUserVotedProducts().subscribe(userVotes => {
                if (userVotes != undefined) {
                    this.votedProductsList.next(userVotes);
                } else {
                    this.votedProductsList.next([]);
                }
                this.filteredContent = this.content.filter(content => {
                    var predicate = false;
                    this.votedProductsList.subscribe(list => {
                        list.forEach(vote => {
                            if (vote.productId == content.productId && vote.rating == 1) {
                                predicate = true;
                            }
                        });
                    });
                    return predicate
                });
            })
            this.showLike = true;
        } else {
            this.filteredContent = this.content;
            this.showLike = false;
        }
    }

    getFilteredProduct(productId: string): Product | undefined {
        var result = undefined;
        this.filteredContent.forEach(content => {
            if (content.productId == productId) {
                result = content;
            }
        })
        return result;
    }

    /*/ will show searchbar /*/
    toggleSearch() {
        if (this.showLike === true) {
            this.toggleGlobalLikeButton();
        }
        this.showSearch = !this.showSearch;
        this.searchClass = this.showSearch ? 'search-background-open' : 'search-background-closed';
        this.searchSpacerClass = this.showSearch ? 'search-spacer-open' : 'search-spacer-closed';
        if (this.showSearch) {
            document.getElementById('search').focus();
        } else {
            document.getElementById('search').blur();
        }
    }

    /*/ clear the search filter /*/
    clearSearch() {
        if (!(this.search === '' || this.search.length === 0)) {
            this.search = '';
            this.filterSearchContent();
        }
    }

    /*/ filters content on keydown (input) /*/
    filterSearchContent() {
        if (!this.showLike && (!this.search || this.search.length === 0)) {
            this.filteredContent = this.content;
        } else {
            this.filteredContent = this.content.filter(product => {
                const lowerCaseSearch = this.search.toLowerCase();
                const searchId = "#" + product.customId;
                const tags = product.tags;
                const productName = product.name.toLowerCase();
                // This approach may be ugly, but its much more performant. With ~150 products every performance gain counts.
                let isHit = false;
                if (searchId.includes(lowerCaseSearch)) {
                    isHit = true;
                } else if(productName.includes(lowerCaseSearch)) {
                    isHit = true;
                } else {
                    let tagHit = false;
                    if (tags != undefined) {
                        let i = 0
                        while (i < tags.length && !tagHit) {
                            let tag = tags[i]
                            if (tag.tagName.toLowerCase() === lowerCaseSearch) {
                                tagHit = true;
                            }
                            i += 1;
                        } 
                    }
                    isHit = tagHit;
                }
                return isHit;
            });
        }
    }

    private updateContent(): void {
        this.loading = true;
        const productOptions = new Array<ProductOptions>(ProductOptions.IMAGES, ProductOptions.VARIANT);
        this.productService.getAllProducts(productOptions).subscribe((products) => {
            this.content = products;
            this.filterSearchContent();
            this.loading = false;
        });
    }

    /*/ toggles the searchbar /*/
    closeSearch() {
        window.scroll(0, 0);
        this.toggleSearch();
    }

    /*/ closes the searchbar /*/
    hideSearch() {
        if (this.showSearch) {
            this.toggleSearch();
        }
    }

    openDashboard() {
        this.tagService.searchTag('');
        this.router.navigate(['dashboard']);
    }


    private filterForTagName(products: Product[], tagName: string): Product[] {
        return products.filter((product) => {
            let tags = product.tags;
            if (tags == undefined || tags.length == 0) {
                return false
            }
            let match = tags.filter((tag) => tag.tagName == tagName);
            if (match.length > 0) {
                return true;
            } else {
                return false
            }
        })
    }

    productIsLiked(productId: String): boolean {
        let isLiked = false;
        this.votedProductsList.subscribe(list => {
            let filtered = list.filter(vote => vote.productId == productId && vote.rating == 1);
            isLiked = filtered != undefined && filtered.length != 0;
        })       
        return isLiked;
    }
}
