import {UserRestService} from './user-rest.service';
import {UserUpdateRequest} from '../model/request/UserUpdateRequest';
import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {ConfigService} from './config-rest.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class TranslateDataService {
    language: string;
    behaviourSubject: BehaviorSubject<string>;
    observable: Observable<string>;

    behaviorChangeSubject: BehaviorSubject<boolean>;
    changeObservable: Observable<boolean>;

    constructor(private userService: UserRestService, private configService: ConfigService) {
        this.behaviourSubject = new BehaviorSubject<string>(undefined);
        this.observable = this.behaviourSubject.asObservable();

        this.behaviorChangeSubject = new BehaviorSubject<boolean>(false);
        this.changeObservable = this.behaviorChangeSubject.asObservable();

        this.language = this.getNavigatorLanguage();
    }

    setLanguage(language: string, changeLanguage: boolean) {
        this.language = language;
        if (this.userService.isValidUserId() && changeLanguage) {
            this.userService.putUser(new UserUpdateRequest(language)).subscribe((user) => {
                localStorage.setItem(environment.localStorageName, user.userId);
                this.behaviorChangeSubject.next(true);
                this.configService.getTenantConfig().subscribe();
            });
        }
        this.behaviourSubject.next(language);
    }

    getNavigatorLanguage() {
        if (navigator.language.toLowerCase().includes('de')
            || navigator.language.toLowerCase().includes('at')
            || navigator.language.toLowerCase().includes('ch')) {
            return 'de';
        }
        return 'en';
    }

    setLanguageToNavigatorLanguage() {
        this.setLanguage(this.getNavigatorLanguage(), true);
    }

    resetLanguageChangeObservable() {
        this.behaviorChangeSubject.next(false);
    }
}
