import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';


/**
 * Abstract base class for all kinds of REST API calls.
 */
export abstract class AbstractRestService {

    private httpClient: HttpClient;

    protected abstract get resourcePathSegment(): string;

    protected constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    protected get baseUrl(): string {
        return environment.baseUrl;
    }

    /**
     * Returns a HttpHeaders componentInstance with default configuration. Add your own parameters for a request like this:
     *
     * <tt>const headers: HttpHeaders = this.getHeaders().set('myHeadParamName1', myParamValue1).set('myHeadParamName2', myParamValue2);</tt>
     * @returns {HttpHeaders}
     */
    protected getHeaders(): HttpHeaders {
        const userId: string = localStorage.getItem(environment.localStorageName);
        if (userId != null) {
            return new HttpHeaders().set('uid', userId);
        }
        return new HttpHeaders();
    }

    /**
     * Returns a HttpParams componentInstance with default configuration. Add your own parameters for a request like this:
     *
     * <tt>const params: HttpParams = this.getRequestParams().set('myParamName1', myParamValue1).set('myParamName2', myParamValue2);</tt>
     */
    protected getRequestParams(): HttpParams {
        return new HttpParams(); //.set(CORSUtil.key, CORSUtil.value);
    }

    /**
     * Makes a HTTP GET request with the specified parameters.
     * @param {string} relativePath the relative path (from your configured base URL)  to make the request to.
     * @param {HttpParams} params the request params to add to the request
     * @param {HttpHeaders} headers the header parameters to use for the request
     * @returns {Observable<any>} an Observable containing a JSON response
     */
    protected get(relativePath: string, params?: HttpParams, headers?: HttpHeaders): Observable<any> {

        const url: string = this.createURL(relativePath);

        // use default params if values were not explicitly passed over to this method
        if (!params) {
            params = this.getRequestParams();
        }

        // use default headers if values were not explicitly passed over to this method
        if (!headers) {
            headers = this.getHeaders();
        }

        return this.httpClient.get(url, {headers, params});
    }

    private createURL(relativePath: string): string {
        const pathSegements: Array<string> = [];
        pathSegements.push(this.baseUrl);
        if (this.resourcePathSegment) {
            pathSegements.push(this.resourcePathSegment);
        }
        if (relativePath && relativePath != "") {
            pathSegements.push(relativePath);
        }
        return pathSegements.join('/');
    }


    /**
     * Makes a HTTP POST request with the specified request body and parameters.
     * @param {string} relativePath the relative path (from your configured base URL)  to make the request to.
     * @param body the body (data object) to send along with the POST request
     * @param {HttpParams} params the request params to add to the request
     * @param {HttpHeaders} headers the header parameters to use for the request
     * @returns {Observable<any>} an Observable containing a potential JSON response
     */
    protected post(relativePath: string, body: any, params?: HttpParams, headers?: HttpHeaders): Observable<any> {

        const url: string = this.createURL(relativePath);

        // use default params if values were not explicitly passed over to this method
        if (!params) {
            params = this.getRequestParams();
        }

        // use default headers if values were not explicitly passed over to this method
        if (!headers) {
            headers = this.getHeaders();
        }

        return this.httpClient.post(url, body, {headers, params});
    }

    /**
     * Makes a HTTP PUT request with the specified request body and parameters.
     *
     * @param {string} relativePath the relative path (from your configured base URL)  to make the request to.
     * @param body the body (data object) to send along with the PUT request
     * @param {HttpParams} params the request params to add to the request
     * @param {HttpHeaders} headers the header parameters to use for the request
     * @returns {Observable<any>} an Observable containing a potential JSON response
     */
    protected put(relativePath: string, body: any, params?: HttpParams, headers?: HttpHeaders): Observable<any> {

        const url: string = this.createURL(relativePath);

        // use default params if values were not explicitly passed over to this method
        if (!params) {
            params = this.getRequestParams();
        }

        // use default headers if values were not explicitly passed over to this method
        if (!headers) {
            headers = this.getHeaders();
        }

        return this.httpClient.put(url, body, {headers, params});
    }

    protected delete(relativePath: string, params?: HttpParams, headers?: HttpHeaders): Observable<any> {

        const url: string = this.createURL(relativePath);

        // use default params if values were not explicitly passed over to this method
        if (!params) {
            params = this.getRequestParams();
        }

        // use default headers if values were not explicitly passed over to this method
        if (!headers) {
            headers = this.getHeaders();
        }

        return this.httpClient.delete(url, {headers, params});
    }

    public createGETRequestURL(relativePath: string, params?: HttpParams): string {
        const url: string = this.createURL(relativePath);
        if (!params) {
            params = this.getRequestParams();
        }
        if (url.indexOf('?') >= 0) {
            return url + '&' + params.toString();
        }
        return url + '?' + params.toString();
    }

}
