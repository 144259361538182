export class Agenda {
    public description: string;
    public endDate: Date;
    public name: string;
    public eventId: number;
    public startDate: Date;

    constructor(description: string,
                endDate: string,
                name: string,
                eventId: number,
                startDate: string) {
        this.description = description;
        this.endDate = new Date(endDate);
        this.name = name;
        this.eventId = eventId;
        this.startDate = new Date(startDate);
    }

    static fromJson(json: any): Agenda {
        return new Agenda(json.description, json.endDate, json.name, json.eventId, json.startDate);
    }
}
