import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscriber, map} from 'rxjs';
import {VotesmessageComponent} from '../framework/votesmessage/votesmessage.component';
import {MatDialog} from '@angular/material/dialog';
import { Product } from '../model/Product';
import { HttpClient } from '@angular/common/http';
import { VariantVoteRequest } from '../model/request/VariantVoteRequest';
import { AbstractRestService } from './abstract-rest.service';
import { UserRestService } from './user-rest.service';
import { VaraintId } from '../model/VariantId';
import { VoteRatingRequest } from '../model/request/VoteRatingRequest';
import { error } from 'console';

@Injectable({
    providedIn: 'root'
})
export class VoteService extends AbstractRestService {
    protected get resourcePathSegment(): string {
        return "vote"
    }
    votesLeft: BehaviorSubject<number>;
    votes: number;
    maxVotes: number;

    constructor(
        public dialog: MatDialog,
        private userService: UserRestService,
        http: HttpClient
    ) {
        super(http);
        this.votesLeft = new BehaviorSubject(undefined);
        this.getRemainingLikes().subscribe(likes => {
            this.votesLeft.next(likes);
        });
        this.votes = 0;
        this.maxVotes = 0;
    }

    deleteVote() {
        this.votes--;
        if (this.votes === 0) {
            this.openVoteMessageModal();
        }
        this.votesLeft.next(this.votes);
    }

    private addVote() {
        this.votes++;
        this.votesLeft.next(this.votes);
    }

    setVotes(votes: number) {
        this.votes = votes;
        if (this.votes === 0) {
            this.openVoteMessageModal();
        }
        this.votesLeft.next(this.votes);
    }

    getRemainingLikes(): Observable<number> {
        return this.userService.getUser().pipe(map( user => {
            this.votesLeft.next(user.votesLeft);
            this.votes = user.votesLeft
            return this.votes;
        }))
    }

    openVoteMessageModal() {
        this.dialog.open(VotesmessageComponent, {
            width: '50%',
            panelClass: ['col-lg-4', 'col-12', 'votemessage-background']
        });
    }

    voteForProduct(product: Product): Observable<any> {
        let request = new VoteRatingRequest(1, "DEFAULT");
        return this.put(product.productId + "/ratingOption", request).pipe(value => {
            return value
        })
    }

    unvoteForProduct(product: Product) {
        let request = new VoteRatingRequest(0, "DEFAULT");
        this.put(product.productId + "/ratingOption", request).subscribe(_ => {
            this.addVote();
        })
    }

    voteForVariant(variantIds: VaraintId[], productId: String, isLiked: boolean) {
        let request = new VariantVoteRequest(productId, isLiked ? 1 : 0, variantIds);
        this.put(productId + "/variant", request).subscribe(_ => {});
    }
}
