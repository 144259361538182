// This is a mask that is used to sort the tags according to the wishes of our masters... This should be done and could have been done by the server. But the deadline
// is slowly but shurely approaching and I don't have enough time to rework the tag system and there is already data in the live system.
// TODO: REMOVE THIS AND MAKE IT BETTER!
export const TagToTagIdMask = new Map<string, string>([
    ['HPS', ''],
    ['MPS', ''],
    ['COPS', ''],
    ['CMP', ''],
    ['DH', ''],
    ['MAIC', ''],
    ['OSF', ''],
    ['PHB', ''],
    ['WA', ''],
    ['CA', ''],
    ['GC', ''],
    ['PH', ''],
    ['ADV', ''],
    ['DG', ''],
    ['PHG', ''],
    ['PC', ''],
])