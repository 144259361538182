import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OverviewComponent} from './routing/overview/overview.component';
import {ResultsComponent} from './routing/results/results.component';
import {DashboardComponent} from './routing/dashboard/dashboard.component';
import {LoginComponent} from './routing/login/login.component';
import {AuthGuardService as AuthGuard} from './services/auth-guard.service';
import {FinalComponent} from './routing/final/final.component';
import {AgendaComponent} from './routing/agenda/agenda.component';
import { InnodaysDashboardSimpleComponent } from './routing/dashboard/innodays-dashboard-simple/innodays-dashboard-simple.component';

const routes: Routes = [
    {path: 'overview', component: OverviewComponent, canActivate: [AuthGuard]},
    {path: 'results', component: ResultsComponent, canActivate: [AuthGuard]},
    {path: 'login', component: LoginComponent},
    {path: 'dashboard', component: InnodaysDashboardSimpleComponent},
    {path: 'agenda', component: AgendaComponent},
    {path: 'final', component: FinalComponent},
    {path: '**', redirectTo: 'dashboard'},
    {path: '', redirectTo: 'dashboard', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true, relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
