import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TranslateDataService } from 'src/app/services/translate.service';

export interface VotingStatusDialogData {
  statusCode: number
}

@Component({
  selector: 'cewe-voting-status-dialog',
  templateUrl: './voting-status-dialog.component.html',
  styleUrls: ['./voting-status-dialog.component.scss']
})
export class VotingStatusDialogComponent {
  statusCode: number;

  constructor(
    public dialogRef: DialogRef<VotingStatusDialogComponent>,
    public translateService: TranslateDataService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) private data: VotingStatusDialogData,
  ) { 
      this.statusCode = data.statusCode;
  }
}
