export class VoteRatingRequest {
    public rating: number;
    public ratingOptionId: string;

    constructor(rating: number, ratingOptionId: string) {
        this.rating = rating;
        this.ratingOptionId = ratingOptionId;
    }

    static fromJson(json: any): VoteRatingRequest {
        return new VoteRatingRequest(json.rating, json.ratingOptionId);
    }
}
