import {Component, OnInit} from '@angular/core';
import {TranslateDataService} from '../../../services/translate.service';
import {TranslateService} from '@ngx-translate/core';
import {AbstractTranslationService} from '../../../services/abstract-translation.service';
import { Router } from '@angular/router';
import { UserRestService } from 'src/app/services/user-rest.service';
import { User } from 'src/app/model/User';

@Component({
    selector: 'cewe-innodays-dashboard-simple',
    templateUrl: './innodays-dashboard-simple.component.html',
    styleUrls: ['./innodays-dashboard-simple.component.scss']
})
export class InnodaysDashboardSimpleComponent extends AbstractTranslationService implements OnInit {

    user: User

    constructor(public translateService: TranslateDataService,
                public translate: TranslateService,
                private userService: UserRestService,
                public router: Router) {
        super(translate, translateService);
    }

    ngOnInit() {
        this.userService.getUser().subscribe(user => {
            this.user = user
        })
    }

    openAgenda() {
        if (this.user != undefined) {
            this.router.navigate(["agenda"]);
        } else {
            this.router.navigate(["login"]);
        }
    }

    openOverview() {
        if (this.user != undefined) {
            this.router.navigate(["overview"]);
        } else {
            this.router.navigate(["login"]);
        }
    }
}
