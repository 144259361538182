import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AbstractTranslationService } from 'src/app/services/abstract-translation.service';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { ConfigService } from 'src/app/services/config-rest.service';
import { TranslateDataService } from 'src/app/services/translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cewe-final',
  templateUrl: './final.component.html',
  styleUrls: ['./final.component.scss']
})
export class FinalComponent extends AbstractTranslationService implements OnInit {
  @Input()
  ngSwitch: any;

  authType = '';
  loggedIn: boolean;
  tenantId: number;

  constructor(public router: Router,
              private configService: ConfigService,
              public translateService: TranslateDataService,
              public translate: TranslateService,
              private authService: AuthGuardService) {
      super(translate, translateService);

      this.configService.observable.subscribe((configMap) => {
          this.authType = configService.authType;
      });
  }

  ngOnInit() {
      this.tenantId = environment.tenantId;
      this.authService.observable.subscribe(loggedIn => {
          this.loggedIn = loggedIn;
      });
  }
}
