import { Component, Input, OnInit } from '@angular/core';
import { ProductResult } from 'src/app/model/ResultResponse';

@Component({
  selector: 'cewe-result-product',
  templateUrl: './result-product.component.html',
  styleUrls: ['./result-product.component.scss']
})
export class ResultProductComponent implements OnInit {
  @Input() product: ProductResult
  @Input() placement: number;
  germanName: string = "";
  englishName: string = "";

  constructor() { }

  ngOnInit(): void {
    this.product.translations.forEach(translation => {
      if (translation.language == "de") {
        this.germanName = translation.name;
      } else if (translation.language == "en") {
        this.englishName = translation.name;
      }
    });
  }

}
