import { AfterViewInit, Directive, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgIf } from '@angular/common';

/**
 * A lazy load for a container. It waits until the container content is visible and load the container.
 *
 * Idea taken from https://medium.com/@realTomaszKula/lazy-load-images-in-30-lines-of-code-3fe801223ffa
 */
@Directive({
  selector: '[appLazyLoad]'
})
export class LazyLoadDirective extends NgIf implements AfterViewInit {

  constructor(viewContainer: ViewContainerRef,
              templateRef: TemplateRef<any>,
              private el: ElementRef) {
    super(viewContainer, templateRef);
  }

  ngAfterViewInit(): void {
    if (this.canLazyLoad()) {
      this.lazyLoad();
    } else {
      // lazy load is not supported, show the content
      this.showContent();
    }

  }

  private canLazyLoad() {
    return window && 'IntersectionObserver' in window;
  }

  private lazyLoad() {
    // The container is not rendered and therefore we have no dom node to attach the observer to.
    // -> We create an invisible (see styling in the style.scss) span at the containers position use that.
    const span = document.createElement('span');
    span.className = 'lazy-span';

    const obs = new IntersectionObserver(entries => {
      entries.forEach(({isIntersecting}) => {
        if (isIntersecting) {
          this.showContent();
          obs.unobserve(span);
        }
      });
    });

    // put the new span as a sibling before the container
    const parent = this.el.nativeElement.parentNode;
    parent.insertBefore(span, this.el.nativeElement);
    obs.observe(span);
  }

  private showContent() {
    this.ngIf = true;
  }
}
