<!-- close-button -->
<button class="close" (click)="closeClick()"><span class="material-icons" style="font-size: 20px;">close</span></button>

<!-- header -->
<h6 id="feedBackHeadline">#{{data.feedbackProduct.customId}} - {{data.feedbackProduct.name}}</h6>

<!-- input -->
<textarea id="feedbackInput" type="text" class="feedbackInput" placeholder="Feedback..." [value]="feedback"
          spellcheck="false" (keyup)="initializeFeedback($event.target.value)"
          maxlength="1000"></textarea>

<!-- subline -->
<div class="feedback-button-box container">
    <div class="row">
        <div class="possibleCharacters col-2">
            <span id="possibleCharacters">{{feedbackPossibleCharacters}}</span>
        </div>
        <div class="col-2"></div>

        <!-- send -->
        <button mat-raised-button [disabled]="feedback.length === 0 || feedback === savedFeedback"
                class="col-4 feedback-button" (click)="sendFeedback()">
            {{'feedback.send' | translate}}
        </button>
        <div class="col-2"></div>

        <!-- delete -->
        <button mat-icon-button [disabled]="feedback.length < 1" class="col-1"
                (click)="deleteFeedbackForProduct(data.feedbackProduct.productId)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>

