import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from "@angular/core";

/**
 * Adds the api access key to the header of every request.
 */
@Injectable()
export class ApiAccessKeyInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const aak = environment.apiAccessKey;
    if (aak) {
      const newHeaders = req.headers.append('ID-ak', aak);
      req = req.clone({
        headers: newHeaders
      });
    }

    return next.handle(req);
  }
}
