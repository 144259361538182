<cewe-header></cewe-header>

<!-- search icon -->
<div class="search-icon" (click)="toggleSearch()">
    <img *ngIf="showSearch || search.length > 0" src="assets/lupe-circle.svg" alt="Search">
</div>

<div class="search-icon-off" (click)="toggleSearch()">
    <img *ngIf="!showSearch && search.length == 0" src="assets/lupe3.svg" alt="Search">
</div>

<!-- search -->
<div [ngClass]="searchClass">
    <input id="search" type="text" class="search" placeholder="{{'overview.search' | translate}}" [value]="search"
           (input)="search = $event.target.value" spellcheck="false" (keyup)="filterSearchContent()"
           (keydown.enter)="closeSearch()" (keydown.return)="closeSearch()" maxlength="30">

    <div *ngIf="search !== ''" class="search-close" (click)="clearSearch()">
        <img src="assets/close-{{this.tenantId}}.svg" alt="Close">
    </div>
</div>
<div [ngClass]="searchSpacerClass"></div>

<!-- LikeFilter icon -->
<ng-container *ngIf="!this.starsButton">
    <div class="likeFilter-icon" (click)="toggleGlobalLikeButton()">
        <img *ngIf="showLike" style="height: 28px;width: auto" src="assets/like-circle.svg" alt="Likes">
        <div *ngIf="!showLike && this.votesLeft" class="numOfLikes">{{this.votesLeft}}</div>
    </div>
    <div class="likeFilter-icon-off" (click)="toggleGlobalLikeButton()">
        <img *ngIf="!showLike" style="height: 28px;width: auto" src="assets/like.svg" alt="Likes">
    </div>
</ng-container>
<!-- translation toggle -->
<div class="translation-icon">
    <cewe-translation-toggle></cewe-translation-toggle>
</div>

<!-- overview container -->
<div class="overview-background" id="tile" (click)="hideSearch()">

    <!-- tiles -->
    <div *ngFor="let content of filteredContent | sortgrid: 'id'">
        <cewe-product class="tile" [product]="content" [isLiked]="this.productIsLiked(content.productId)" [userVotes]="this.votedProductsList"></cewe-product>
    </div>

    <!-- loading screen -->
    <div class="tile-noResult" *ngIf="filteredContent.length == 0 && loading">
        <p>{{'overview.loading' | translate}}</p>
        <img id="loadingSpinner" class="rotation" src="assets/loadingSpinner_Circle.svg" alt="Loading">
    </div>
    <!-- search no results -->
    <div class="tile-noResult" *ngIf="filteredContent.length == 0 && (!loading && !showLike)">
        <p>{{'overview.emptySearch' | translate}}</p>
    </div>
    <!-- like filter without results -->
    <div class="tile-noResult" *ngIf="filteredContent.length == 0 && (!loading && showLike)">
        <p>{{'overview.emptyFavorites' | translate}}</p>
    </div>

    <!-- footer -->
    <div id="footer" class="footer-background" *ngIf="filteredContent.length > 0 && !loading && voteService.votes">
        <span class="footer-counter">Likes: {{voteService.votes}}</span>
    </div>

    <button *ngIf="filteredContent.length > 0 && !loading" class="home-background" (click)="openDashboard()">
        <img src="assets/home-overview.svg" alt="Home">
    </button>

    <div class="footer-spacer"></div>
</div>

<!-- version -->
<span class="version" style="visibility: hidden" *ngIf="!loading && !showLike && filteredContent.length != 0">{{version}}</span>
