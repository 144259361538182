<div class="wrapper">
    <cewe-header></cewe-header>

    <div class="translation-icon">
        <cewe-translation-toggle></cewe-translation-toggle>
    </div>
    <div class="container">
        <img class="bulb" src="assets/Innodays-2024.svg" alt="Innodays 2024 Logo">
        <div class="wave_container">
            <img src="assets/wave_chopped.gif">
        </div>
        <h1 class="welcome">{{'dashboard.welcome' | translate}}</h1>
        <div class="buttonGroup">
            <button (click)="openOverview()">
                {{'dashboard.toTheInnovation' | translate}} <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
            </button>
            <button (click)="openAgenda()">
                {{'dashboard.agenda' | translate}} <mat-icon class="arrow">keyboard_arrow_right</mat-icon>  
            </button>
        </div>
    </div>
</div>