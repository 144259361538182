import { VaraintId } from "../VariantId";

export class VariantVoteRequest {
    productId: String;
    rating: number;
    variantVotes: VaraintId[];

    constructor(productId: String, rating: number, variantVotes: VaraintId[]) {
        this.productId = productId;
        this.rating = rating;
        this.variantVotes = variantVotes;
    }

    static fromJson(json: any): VariantVoteRequest {
        return new VariantVoteRequest(json.productId, json.rating, json.variantVotes);
    }
}