import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {UserRestService} from '../../services/user-rest.service';
import {User} from '../../model/User';
import {Router} from '@angular/router';
import {AuthGuardService} from '../../services/auth-guard.service';
import {UserRequest} from 'src/app/model/request/UserRequest';
import {TranslateDataService} from 'src/app/services/translate.service';
import {ConfigService} from '../../services/config-rest.service';
import {AbstractTranslationService} from '../../services/abstract-translation.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'cewe-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AbstractTranslationService implements OnInit {
    password = '';
    wrongPassword = false;
    falseUserId: boolean;
    assets: string;
    username: string;
    authType: string;
    hideLogin : boolean = true;

    constructor(private readonly userService: UserRestService,
                private router: Router,
                private authGuard: AuthGuardService,
                public translate: TranslateService,
                public translateService: TranslateDataService,
                private configService: ConfigService) {
        super(translate, translateService);

        this.configService.observable.subscribe((configMap) => {
            this.authType = this.configService.authType;
            if (this.userService.isValidUserId() || this.authType === 'NONE') {
                this.router.navigate(['overview']).then(r => ['']);
            }
            if (!this.authType) {
                this.router.navigate(['dashboard']).then(r => ['']);
            }
        });
    }

    ngOnInit() {
        this.falseUserId = environment.falseUserId;
    }


    myFunction() {
      this.hideLogin = !this.hideLogin;
    }

    login() {
        this.wrongPassword = false;
        this.falseUserId = false;
        this.userService.postUser(new UserRequest(this.translateService.language, environment.tenantId, this.password)).subscribe({
            next: (user: User) => {
                localStorage.setItem(environment.localStorageName, user.userId);
                this.authGuard.loggedIn = true;
                this.router.navigate(['overview']);
            }, 
            error: () => this.wrongPassword = true,
        });
    }
}
