<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            {{formatedStartDate}} <br> {{formatedEndDate}}
        </mat-panel-title>
        <mat-panel-description>
            <p class="done" *ngIf="eventIsDone">{{event.name}}</p>
            <p *ngIf="!eventIsDone">{{event.name}}</p>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <p>{{event.description}}</p>
</mat-expansion-panel>