import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {TranslateDataService} from 'src/app/services/translate.service';
import {ConfigService} from '../../services/config-rest.service';

@Component({
    selector: 'cewe-translation-toggle',
    templateUrl: './translation-toggle.component.html',
    styleUrls: ['./translation-toggle.component.scss']
})
export class TranslationToggleComponent implements OnInit {
    languages: any[] = [];

    toggle = true;
    selectedLanguageClass = 'selectedLanguage closed';
    circleClass = 'circle closed';
    dropdownClass = 'dropdown closed';

    constructor(public translateService: TranslateDataService,
                private configService: ConfigService,
                private eRef: ElementRef) {
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.eRef.nativeElement.contains(event.target) && !this.toggle) {
            this.toggleCircle();
        }
    }

    ngOnInit() {
        this.configService.observable.subscribe((client) => {
            this.languages = this.configService.supportedLanguages;
        });
    }

    toggleCircle() {
        if (this.toggle) {
            this.selectedLanguageClass = 'selectedLanguage open';
            this.circleClass = 'circle open';
            this.dropdownClass = 'dropdown open';
            this.toggle = false;
        } else {
            this.selectedLanguageClass = 'selectedLanguage closed';
            this.circleClass = 'circle closed';
            this.dropdownClass = 'dropdown closed';
            this.toggle = true;
        }
    }

    setLanguage(language: string) {
        this.translateService.setLanguage(language.toLowerCase(), true);
    }
}
