import { Component, Input, OnInit } from '@angular/core';
import { Agenda } from 'src/app/model/Agenda';

@Component({
  selector: 'cewe-agenda-descriptor',
  templateUrl: './agenda-descriptor.component.html',
  styleUrls: ['./agenda-descriptor.component.scss']
})
export class AgendaDescriptorComponent implements OnInit {
  @Input() event: Agenda;

  formatedStartDate: string = "";
  formatedEndDate: string = "";
  eventIsDone = false;
  
  constructor() { }

  ngOnInit(): void {
    this.formatedStartDate = this.formatEventDate(this.event.startDate);
    this.formatedEndDate = this.formatEventDate(this.event.endDate);
    this.eventIsDone = this.event.endDate.getTime() < new Date().getTime();
  }

  private formatEventDate(date: Date): string {
    let hour = date.getHours();
    let minute = date.getMinutes();
    let seconds = date.getSeconds();

    let h_str = hour.toString();
    if (hour < 10) {
      h_str = "0" + hour.toString();
    }

    let m_str = minute.toString();
    if (minute < 10) {
      m_str = "0" + minute.toString();
    }

    let s_str = seconds.toString();
    if (seconds < 10) {
      s_str = "0" + seconds.toString();
    }

    return h_str + ":" + m_str;
  }
}
