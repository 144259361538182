<form *ngIf="!this.multipleSelectable" class="example-form" [formGroup]="formGroup" (ngSubmit)="alertFormValues(formGroup)" ngNativeValidate>
    
    <div *ngFor="let variant of this.variants" class="formContainer">
        <label>{{this.getVariantTitle(variant.id)}}</label>
        <mat-slide-toggle 
        labelPosition="before" 
        formControlName="{{variant.id}}"
        (change)="slideButtonClicked(variant.id)"
        ></mat-slide-toggle>
    </div>
</form>

<div *ngIf="this.multipleSelectable">
    <section class="example-section" [formGroup]="formGroup">
        <div *ngFor="let variant of this.variants" class="formContainerMultiple">
            <label>{{this.getVariantTitle(variant.id)}}</label>
            <mat-checkbox labelPosition="before" (change)="checkmarkClicked()" formControlName="{{variant.id}}"></mat-checkbox>
        </div>
    </section>
</div>