import {Component, Inject, OnInit} from '@angular/core';
import {ProductRestService} from '../../../services/product-rest.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FeedbackDialogData} from '../product/product.component';
import {Product} from 'src/app/model/Product';
import {UserRestService} from 'src/app/services/user-rest.service';
import * as emoji from 'node-emoji';
import {TranslateService} from '@ngx-translate/core';
import {TranslateDataService} from '../../../services/translate.service';
import {AbstractTranslationService} from '../../../services/abstract-translation.service';

@Component({
    selector: 'cewe-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent extends AbstractTranslationService implements OnInit {
    feedback = '';
    feedbackPossibleCharacters = 1000;
    savedFeedback = '';
    feedbackProduct: Product;

    constructor(public readonly productService: ProductRestService,
                public userService: UserRestService,
                public snackbar: MatSnackBar,
                public dialogRef: MatDialogRef<FeedbackComponent>,
                @Inject(MAT_DIALOG_DATA) public data: FeedbackDialogData,
                public translate: TranslateService,
                public translateService: TranslateDataService) {
        super(translate, translateService);

        this.feedbackProduct = this.data.feedbackProduct;
        this.initializeFeedback(this.userService.userCommtendProductsMap.get(this.feedbackProduct.productId) || '');
        this.savedFeedback = this.feedback;
    }

    ngOnInit() {
        (document.querySelector('html') as HTMLElement).style.overflowY = 'unset';
    }

    initializeFeedback(feedback) {
        this.feedback = feedback;
        this.feedback = emoji.emojify(this.feedback);
        this.feedbackPossibleCharacters = 1000 - this.feedback.length;
    }

    deleteFeedbackForProduct(productId) {
        if (this.feedback.length > 0 && this.savedFeedback.length > 0) {
            this.productService.deleteProductComment(productId).subscribe(() => {
                this.translate.get('feedback.deleted').subscribe((deleted: string) => {
                    this.triggerSnackbar(deleted, '');
                    this.dialogRef.close();
                });
            });
            this.userService.userCommtendProductsMap.delete(this.feedbackProduct.productId);
        } else {
            this.initializeFeedback('');
        }
    }

    sendFeedback() {
        if ((this.feedback !== '' || this.feedback.length !== 0) && this.feedback !== this.savedFeedback) {
            this.userService.userCommtendProductsMap.set(this.feedbackProduct.productId, emoji.unemojify(this.feedback));
            this.productService.createOrUpdateProductComment(this.feedbackProduct.productId, emoji.unemojify(this.feedback)).subscribe((res) => {
                this.translate.get('feedback.sended').subscribe((sended: string) => {
                    this.triggerSnackbar(sended, '');
                    this.dialogRef.close();
                });
            });
        }
    }

    private triggerSnackbar(message: string, action: string) {
        this.snackbar.open(message, action, {
            panelClass: 'snackbar-background',
            duration: 2500
        });
    }

    closeClick() {
        this.dialogRef.close();
    }
}
