import {TranslateService} from '@ngx-translate/core';
import {TranslateDataService} from './translate.service';

export abstract class AbstractTranslationService {

    protected constructor(public translate: TranslateService,
                          public translateService: TranslateDataService) {
        translate.setDefaultLang(translateService.getNavigatorLanguage());

        this.translateService.observable.subscribe((language) => {
            translate.use(language);
        });
    }
}
