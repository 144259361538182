import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Directive, HostListener, Input, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ResultResponse } from 'src/app/model/ResultResponse';
import { ResultsRestService } from 'src/app/services/results-rest-service';
import { TagRestService } from 'src/app/services/tag-rest.service';
import { TranslateDataService } from 'src/app/services/translate.service';
import { TagToTagnameMap } from './tag_tagname';

const AMOUNT_OF_PRODUCTS = 3;

@Component({
    selector: 'cewe-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultsComponent implements OnInit {
    tagList: string[];
    tagId = "";
    currentIndex = 0;
    endSequence = false;

    results: ResultResponse;

    tag: string;
    tagName: string;


    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private tagService: TagRestService,
        private cd: ChangeDetectorRef,
        private resultService: ResultsRestService,
        public translateService: TranslateDataService,
        public translate: TranslateService,
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
              this.tagId = params['tag'];
        });
      }

    ngOnInit(): void {
        if (this.tagId == "" || this.tagId == undefined) {
            if (this.tagList != undefined && this.tagList.length > 0 && this.currentIndex < this.tagList.length) {
                this.tagId = this.tagList[this.currentIndex];
                this.fetchTagResults();
            }
        } else {
            this.fetchTagResults();
        }
    }

    getTagsFromStart(event: string[]) {
        this.tagList = event;
        this.tagId = this.tagList[this.currentIndex];
        this.fetchTagResults();
    }

    @HostListener('document:keydown.ArrowRight', ['$event'])
    next(): void {
        if (this.currentIndex + 1 < this.tagList.length) {
            this.currentIndex += 1;
            this.tagId = this.tagList[this.currentIndex];
            this.fetchTagResults();
        } else {
            this.endSequence = true;
            this.tagId = "ENDING";
            this.currentIndex = this.tagList.length;
        }
    }

    @HostListener('window:keydown.ArrowLeft', ['$event'])
    before(): void {
        if (this.currentIndex - 1 >= 0) {
            this.currentIndex -= 1;
            this.tagId = this.tagList[this.currentIndex];
            this.fetchTagResults();
            if (this.endSequence) {
                this.endSequence = false;
            }
        }
    }

    isNextAvailable(): boolean {
        if (this.tagList != undefined) {
            return this.currentIndex + 1 <= this.tagList.length; //End sequence is next and last element
        } else {
            return false;
        }
    }

    isBeforeAvailable(): boolean {
        if (this.tagList != undefined) {
            return this.currentIndex - 1 >= 0;
        } else {
            return false;
        }
    }

    private fetchTagResults(): void {
        this.resultService.getResultsOfTag(this.tagId, AMOUNT_OF_PRODUCTS).subscribe(result => {
            this.results = result;
            this.getInformationFromResult();
            this.cd.markForCheck();
        })
    }

    private getInformationFromResult(): void {
        this.tag = this.results.tagTranslations[0].tagName;
        let tagname = TagToTagnameMap.get(this.tag);
        this.tagName = tagname;
    }
}
