import {Agenda} from './Agenda';

export class EventDay {
    public date: Date;
    public events: Agenda[];

    constructor(date: Date, events: Agenda[]) {
        this.date = date;
        this.events = events;
    }
}
