export class UserRequest {
    constructor(public language: string,
                public tenantId: number,
                public password: string) {
    }

    static fromJson(json: any): UserRequest {
        return new UserRequest(json.language, json.tenantId, json.password);
    }
}
